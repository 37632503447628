/* eslint-disable react/jsx-no-target-blank */
import React,{useState, useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Toast from 'react-bootstrap/Toast';
import axios from 'axios';
import '../css/contactus.css';

import FadeIn from 'react-fade-in';

// import ArrowLeft from '../images/Arrow1.svg';
// import ArrowRight from '../images/Arrow1.svg';

var location = ""
//  Custom CSS
const treasury = {
    width: '0px',
    opacity: 0,
    position: 'absolute'
}
// End of Custom CSS

function serialize(form) {
    let inputs = form.elements;
    let testArray = {};
    for(let i=0; i < inputs.length; i++) {
        testArray[(inputs[i].id)] = inputs[i].value;
    }
    return (testArray);
}


function trim(theStr) {
	return theStr.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
}

function getNumbers(inVal, isDouble, removeCharsFromPrefix) {		
    var retVal = "";
    var okChars = "0123456789";
    var tmpVal = trim(inVal);
    var curChar = "";
    var isDotFound = false;
    
    if (tmpVal !== "") {
        for (var i = 0; i < tmpVal.length; i++) {
            curChar = tmpVal.charAt(i);
            
            if (okChars.indexOf(curChar) !==-1) {					
                if (!isDotFound && curChar === "." && isDouble) {
                    isDotFound = true;
                    retVal = retVal + ".";
                }
                
                if (retVal === "" && removeCharsFromPrefix.indexOf(curChar) !== -1) {
                    //Do nothing
                } else {
                    retVal += curChar;
                }
            }
        }
    }
    
    return retVal;
}


export default function ContactUs() {

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [PhoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [howDidYouKnow, setHowDidYouKnow] = useState("");
    const [referrerName, setReferrerName] = useState("");
    const [selectedFile, setFileSelection] = useState(null);
    const [honeyPot, setHoneyPot] = useState(false);
    const [validated, setValidated] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [thankModal, setTYModal] = useState(false);
    const handleTYClose = () =>{
        refreshPage();
    } 

    
    const [showFail, setFailModal] = useState(false)

    const handleFailClose = () => setFailModal(false)

    const [successToast, setSuccessToast] = useState(false);
    const [warningToast, setWarningToast] = useState(false);
    const [toastMsg, setToastMsg] = useState("");

    const [url, setUrl] = useState('');

    function getParams(url) {
        var params = {};
        var parser = document.createElement('a');
        parser.href = url;
        var query = parser.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            params[pair[0]] = decodeURIComponent(pair[1]);
        }
        return params;
    };

    useEffect(() => {
        location = getParams(window.location.href)
        if(location.source != ""){
            setHowDidYouKnow(location.source)
        }
    }, []);

    

    function refreshPage() {
        window.location.reload(false);
        setTYModal(false)
    }

    
    const checkMimeType = (event)=>{
        //getting file object
        let files = event.target.files 
        //define message container
        let err = []
        // list allow mime type
        const types = ['image/png', 'image/jpeg', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
        
        // loop access array
        for(var x = 0; x<files.length; x++) {
            // compare file type find doesn't matach
            if (types.every(type => files[x].type !== type)) {
                // create error message and assign to container   
                err[x] = 'File format is not supported for upload.';
            }
        };
        for(var z = 0; z<err.length; z++) {// if message not same old that mean has error 
            // discard selected file
            setToastMsg(err[z]);
            event.target.value = null;
            setWarningToast(true);
        }
        return true;
    }

    const maxSelectFile = (event)=>{
        let files = event.target.files
        if (files.length > 1) { 
            setToastMsg('Only 1 file can be uploaded at a time');
            event.target.value = null;
            setWarningToast(true);
            return false;
        }
        return true;
    }

    const checkFileSize = (event)=>{
        let files = event.target.files
        let size = 2000000 
        let err = []; 
        for(var x = 0; x<files.length; x++) {
            if (files[x].size > size) {
                err[x] = files[x].type+' is too large, please pick a smaller file.';
            }
        };
        for(var z = 0; z<err.length; z++) {// if message not same old that mean has error 
            // discard selected file
            setToastMsg(err[z]);
            event.target.value = null;
            setWarningToast(true);
        }
        return true;
    }

    const onChangeHandler = event => {
        var files = event.target.files;

        if(maxSelectFile(event) && checkMimeType(event) && checkFileSize(event)) {
            setFileSelection(files);
        }
        if(files.length > 0){
            setWarningToast(false);
        }
    }
    
    const handleTelephoneNumber = evt => {

        //Save Phone number in local storage for Apply

        var tmpVal = trim(getNumbers(evt.currentTarget.value, false, "01"));
        var FirstThreeDigit= tmpVal.substring(0, 3);
        var FirstSixDigit= tmpVal.substring(0, 6);

        /* Below Pattern will check numbers between below ranges
        456, 555, 900,
        100, 101, ..., 199
        211, 311, ..., 911  ***811 removed
        370, 371, ..., 379
        960, 961, ..., 969
        800, 801, ..., 855  ***removed
        866, 877, 888, 800855
        */
        var patt =/^(1[0-9][0-9])|([2-7]11)|(911)|(37[0-9])|(96[0-9])|(456)|(555)|(866)|(877)|(888)|(900)$/;
        var patt2 = /^800855$/;
        if(patt.test(FirstThreeDigit)) {
            evt.currentTarget.value="";
            return;
        } else if(patt2.test(FirstSixDigit)) {
            evt.currentTarget.value="";
            return;
        }

        var curChar = "";
        
        var areaCode = "";
        var firstThree = "";
        var lastFour = "";
        var extension = "";
        
        if (tmpVal !=="") {
            for (var i = 0; i < tmpVal.length; i++) {
                curChar = tmpVal.charAt(i);
                
                if (i < 3) areaCode += curChar;
                if (i < 6 && i > 2) firstThree += curChar;
                if (i < 10 && i > 5) lastFour += curChar;
                if (i > 9) extension += curChar;
            }
        }
        
        var retVal = "";
        
		/* 6/13/2020 - Dennis
			Do not put the parentesis if we only have the first three digits of the phone number.
			Once we are past that then start formatting the phone number. This was the main issue
			why the phone number couldn't be blanked out in it's entirety.
		*/
        if (areaCode !=="" && firstThree === "")
			retVal += areaCode;
		
        else if (areaCode !=="" && firstThree !== "")
			retVal += "(" + areaCode + ")";
				
        if (firstThree !=="")
			retVal += " " + firstThree;
        
        if (lastFour !=="")
			retVal += "-" + lastFour;		
        
        if (extension !=="")
            retVal += " x" + extension;  
           

		setPhoneNumber(retVal);

    };

    function changeClass(ename){
        ename.target.className='form-control';
    };

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const handleSubmit = async event => {
        const form = event.currentTarget;
        event.preventDefault();

        
        if (form.checkValidity() === false) {
          event.stopPropagation();
        } else {
            if (!honeyPot) {
                setFormLoading(true);
                
                const data = document.querySelector('#contactForm');
                let contactForm = document.getElementById("contactForm");
                const getData = serialize(data);
                getData.howDidYouKnow = howDidYouKnow;  

                if(getData.howDidYouKnow == "Referral"){
                    getData.Referral = 'XldConnect-'+getData["howDidYouKnow"]+' by-'+getData["Referrer"];
                } else {
                    getData.Referral = 'XldConnect-'+getData["howDidYouKnow"];
                }
                console.log(getData)
                //upload file to aws 
                if(selectedFile && selectedFile.length > 0){
                    
                    var fileParts = selectedFile[0].name.split('.')
                    var file = fileParts[0].replace(/\s/g, '')+Date.now();
                    var fileName = file+"."+fileParts[1]
                    const baseFile = await toBase64(selectedFile[0]);
                    const body = {
                        file: baseFile,
                        name: fileName
                    }

                    axios.post("https://l8fd98lyc2.execute-api.us-east-1.amazonaws.com/prod/python-s3-upload-new", body)                    
                    .then(response => {                          
                        getData.jobFile = response.data.URL
                            const today = new Date()
                            let date = parseInt(today.getMonth()+1) + "/"+ today.getDate() +"/"+today.getFullYear();
                            
                            const referEmp = {
                                "method": "post",
                                "emp": getData.Referral,
                                "firstName": getData.firstName,                                
                                "lastName": getData.lastName,
                                "email": getData.userEmail,
                                "contactNumber": getData.userPhone,
                                "address": getData.state,
                                "city": getData.city,
                                "jobSelect": "Senior Software Engineer",
                                "resume": getData.jobFile,
                                "date": date
                            }
                    
                            axios.post('https://12cjv4aldg.execute-api.us-west-1.amazonaws.com/prod/referral-Data', referEmp)
                                .then(res => {
                                    console.log(res)
                                })
                                .catch(function(error) {
                                    console.log("referral-Data error status " +  JSON.stringify(error));
                                })
                                   
                        axios.post("https://47qpuliogg.execute-api.us-east-1.amazonaws.com/prod/xldconnect", getData)
                        .then(res =>  {
                            setFormLoading(false);
                            setTYModal(true);
                            setFailModal(false);
                            contactForm.classList.remove("was-validated");
                            contactForm.reset();
                            setFirstName("");
                            setLastName("");
                            setPhoneNumber("");
                            setEmail("");
                            setAddress("");
                            setCity("");
                            setState("");
                            setZipcode("");
                            setHowDidYouKnow("");
                            setReferrerName("");
                        })
                        .catch(function (error) {
                            setFailModal(true);
                            contactForm.classList.remove("was-validated");
                            contactForm.reset();
                            console.log("development error status " +  JSON.stringify(error));
                        })
                    })
                        .catch(error => {
                        setFailModal(true);
                        // alert(JSON.stringify(error));
                    })
                } 
                else {
                    axios.post("https://47qpuliogg.execute-api.us-east-1.amazonaws.com/prod/xldconnect", getData)
                        .then(res =>  {
                            setFormLoading(false);
                            setTYModal(true);
                            setFailModal(false);
                            contactForm.classList.remove("was-validated");
                            contactForm.reset();
                            setFirstName("");
                            setLastName("");
                            setPhoneNumber("");
                            setEmail("");
                            setAddress("");
                            setCity("");
                            setState("");
                            setZipcode("");
                            setHowDidYouKnow("");
                            setReferrerName("");
                        })
                        .catch(function (error) {
                            setFailModal(true);
                            contactForm.classList.remove("was-validated");
                            contactForm.reset();
                            console.log("development error status " +  JSON.stringify(error));
                        })
                }
            };
    
        }
        setValidated(true);
    }

    return (
        <>
            <Container fluid className="contact-section">
                <Row className="form-wrapper justify-content-center">
                    {/* <Col lg={1} className="pl-0 d-none d-md-none d-lg-block">
                         <Image src={ArrowLeft} fluid alt="Left arrow shape" className="w-50" style={{transform:"scaleX(-1)"}} />
                    </Col> */}
                    <Col md={12} lg={10} xl={8}>  
                        <Form className="contact-form" id="contactForm" noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row>
                                <Col className="text-center mb-5">
                                    <h5 className="mb-1">IF YOU ARE LOOKING TO MAKE A DIFFERENCE, THIS IS THE PLACE TO BE.</h5>
                                    <h6>If you are just looking for a job, do not apply here.</h6>
                                </Col>
                            </Row>
                            <Form.Row style={treasury}>
                                <Form.Group as={Col} sm={5} controlId="sunwest_name">
                                    <strong><Form.Label>* Sun West Name: </Form.Label></strong>
                                    <Form.Control type="text" minLength="2" maxLength="12" data-error="Please fill out this field correctly." onChange={e => setHoneyPot(true)} />
                                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} sm={5} controlId="sunwest_code">
                                    <strong><Form.Label>* Sun West Code: </Form.Label></strong>
                                    <Form.Control type="text" pattern="^[0-9a-zA-Z]{4}$" maxLength="4" data-error="Please fill out this field correctly." onChange={e => setHoneyPot(true)} />
                                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="justify-content-around">
                                <Form.Group as={Col} md="5" controlId="firstName">
                                    <Form.Label>First Name*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="firstName"
                                        placeholder="Enter First Name"
                                        value={firstName}
                                        required
                                        onChange={e => {setFirstName(e.target.value)}}
                                    />
                                    <Form.Control.Feedback type="invalid">First Name is required</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="5" controlId="lastName">
                                    <Form.Label>Last Name*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="lastName"
                                        placeholder="Enter Last Name"
                                        value={lastName}
                                        required
                                        onChange={e => {setLastName(e.target.value)}}
                                    />
                                    <Form.Control.Feedback type="invalid">Last Name is required</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="justify-content-around">
                                <Form.Group as={Col} md="5" controlId="userEmail">
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="email"
                                        placeholder="Enter Email Address"
                                        value={email}
                                        onChange={e => {setEmail(e.target.value)}}
                                    />
                                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                                </Form.Group> 
                        
                                <Form.Group as={Col} md="5" controlId="userPhone">
                                    <Form.Label>Telephone</Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        placeholder="Enter Telephone Number"  
                                        minLength={10} 
                                        maxLength={10}
                                        // pattern="^\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})[ ]*(( |x){1}[ ]?([0-9]){1,7}){0,1}$" 
                                        onChange={e => {setPhoneNumber(e.target.value)}} 
                                        autoComplete="off" 
                                        value={PhoneNumber} 
                                        onFocus={changeClass.bind(this)}/>
                                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                                </Form.Group> 
                            </Form.Row>
                            <Form.Row className="justify-content-around">
                                <Form.Group as={Col} md="5" controlId="streetAddress">
                                    <Form.Label>Street Address</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="streetAddress"
                                        placeholder="Enter Street Address"
                                        value={address}
                                        onChange={e => {setAddress(e.target.value)}}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="5" controlId="zipCode">
                                    <Form.Label>Zip Code</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="zipCode"
                                        placeholder="Enter Zip Code"
                                        maxLength={8}
                                        value={zipcode}
                                        onChange={e => {setZipcode(e.target.value)}}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="justify-content-around">
                                <Form.Group as={Col} md="5" controlId="city">
                                    <Form.Label>City</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="city"
                                        placeholder="Enter City"
                                        value={city}
                                        onChange={e => {setCity(e.target.value)}}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="5" controlId="state">
                                    <Form.Label>State</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="state"
                                        placeholder="State"
                                        value={state}
                                        onChange={e => {setState(e.target.value)}}
                                    />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row className={"justify-content-around"}>
                                <Form.Group as={Col} md="5">
                                    <Form.Label>How did you know about us</Form.Label>
                                    <Form.Control as="select" name="howDidYouKnow" value={howDidYouKnow} onChange={e => {setHowDidYouKnow(e.target.value)}}>
                                        <option value="">Select an option</option>
                                        <option value="Referral">Referral</option>
                                        <option value="Facebook">Facebook</option>
                                        <option value="LinkedIn">LinkedIn</option>
                                        <option value="Twitter">Twitter</option>
                                        <option value="Instagram">Instagram</option>
                                        <option value="Naukri.com">Naukri.com</option>
                                        <option value="Stack Overflow">Stack Overflow</option>
                                        <option value="Google">Google</option>
                                        <option value="Other">Other</option>
                                    </Form.Control>
                                </Form.Group>
                               
                               <Form.Group as={Col} md="5" controlId="Referrer">
                               { howDidYouKnow === "Referral" ? <FadeIn>
                                    <Form.Label>Referrer Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="referrer"
                                        placeholder="Enter Referrer Name"
                                        value={referrerName}
                                        onChange={e => {setReferrerName(e.target.value)}}
                                    />
                                    </FadeIn> : null }
                                </Form.Group> 
                            </Form.Row>
                            <Form.Row className="justify-content-around">
                                <Form.Group as={Col} md={8} lg={5} className="minimum-screen" controlId="jobFile" >
                                    <strong><Form.Label>Upload Resume</Form.Label></strong>
                                    <div className="custom-file">
                                    <Form.Control type="file" className="custom-file-input" onChange={onChangeHandler} id="validatedCustomFile" />
                                    <Form.Label className="custom-file-label" value="selected" htmlFor="validatedCustomFile">
                                            {selectedFile && selectedFile.length > 0 && selectedFile[0].name ?<span className="filename-trim">{selectedFile[0].name} </span> : 'Choose your Resume...'}</Form.Label>
                                    <Form.Control.Feedback type="invalid">Please select a file.</Form.Control.Feedback>
                                    <Toast  show={warningToast} delay={5000} className="error-msg-block" autohide>
                                        <Toast.Header closeButton={false}>
                                        <strong className="mr-auto error-msg">{toastMsg}</strong>
                                        </Toast.Header>
                                    </Toast>
                                    </div>
                                </Form.Group>
                                <Form.Group as={Col} md={{ span: 2, offset: 3 }} >
                                    <Toast onClose={() => setSuccessToast(false)} show={successToast} delay={5000} className="border-success" autohide>
                                        <Toast.Header>
                                            <strong className="mr-auto">{toastMsg}</strong>
                                        </Toast.Header>
                                    </Toast>
                                </Form.Group>
                            </Form.Row>                           
                            
                            <Form.Row className="justify-content-center pt-3">
                                <button className="clrd-btn text-white text-center" type="submit">
                                    { formLoading ?
                                            <Spinner as="span" animation="border" size="md" role="status" aria-hidden="true"/> :
                                            "Apply Now"}
                                    { formLoading ? " Sending...":""}</button>
                            </Form.Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
            <Modal size='lg' centered show={thankModal} onHide={handleTYClose} backdrop="static">
			<Modal.Body>
				<p className="main-blue">Thank you for contacting Xl Dynamics India Pvt. Ltd.. We will respond to your message as soon as possible.</p>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={handleTYClose}>Close</Button>
			</Modal.Footer>
		</Modal>
        </>
    )
}
