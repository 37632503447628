import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import ReactPlayer from "react-player";

import InsideSunWest from '../Images/Inside XLD banner-02.svg';
import InsideMobile from '../Images/Inside-XLD-banner.png';



export default function SubFooter() {
    return (
        <>
            <div className="quoteWrapper mt-4">
                <Container className="pt-5 pb-4">
                    <Row className="pt-5 justify-content-end">
                        <Col md={12} lg={10} xl={10}>
                            <p>The Software Engineers at XL Dynamics are at the forefront of latest technological trends. This is a great place for those who are excited about solving problems and keeping pace with emerging technologies.</p>
                        </Col>
                    </Row>
                    <Row className="justify-content-end">
                        <Col xs={10} sm={6} md={5} lg={3}>
                            <h4 className="mb-1"><strong>- Pavan Agarwal,</strong> CEO,</h4>
                            <h6>Sun West Mortgage Company, Inc</h6>
                            <h6>(Client of XL Dynamics)</h6>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Image src={InsideSunWest} fluid className="py-5 mt-4 blueLine d-none d-sm-none d-md-block" /> 
            <Image src={InsideMobile} fluid className="py-5 mt-4 d-block d-sm-block d-md-none" />
            <Container fluid className="my-4 videosWrapper">
                <Row className="justify-content-center">
                    <Col md={3} className="mb-3 text-center">
                        <div className='player-wrapper mb-3'>
                            <ReactPlayer
                                url="https://www.youtube.com/watch?v=WtwSl9GS_s4"
                                width='100%'
                                height='240px'
                            />
                        </div>
                    </Col>
                    <Col md={3} className="mb-3 text-center">
                        <div className='player-wrapper mb-3'>
                            <ReactPlayer
                                url="https://www.youtube.com/watch?v=CF2aDsUDy48"
                                width='100%'
                                height='240px'
                            />
                        </div>
                    </Col>
                    <Col md={3} className="mb-3 text-center">
                        <div className='player-wrapper mb-3'>
                            <ReactPlayer
                                    url="https://www.youtube.com/watch?v=vf0DDE0pUgw"
                                    width='100%'
                                    height='240px'
                                />
                        </div>
                    </Col>
                </Row>
            </Container>   
        </>
    )
}
