import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

import thumb from '../Images/footer/Thumb icon-02.svg';
import instagram from '../Images/footer/Instagram-02.svg';
import youtube from '../Images/footer/Youtube-02.svg';
import Facebook from '../Images/footer/Facebook-02.svg';
import linkedin from '../Images/footer/Linkedin-02.svg';
import Twitter from '../Images/footer/Twitter-02.svg';
import  footerBar from '../Images/footer/Footer patch-02.svg';
import EHO from '../Images/footer/EHO-logo.png';

export default function Footer() {
    return (
        <>
        <footer>
            <Container fluid className="my-5 pb-0 pb-sm-1 pb-md-4 px-3">
                <Row className="justify-content-center">
                    <Col lg={12} className="text-center mb-3">
                        <a title="Like us on Instagram!" style={{margin:"0 5px"}} href="https://www.instagram.com/xldynamics/" target="_blank" rel="noopener noreferrer"><img src={instagram} alt="Facebook Like Icon" height="40" /></a>&nbsp;
                        <a title="Follow us on Youtube!" style={{margin:"0 5px"}} href="https://www.youtube.com/channel/UCLDjW6JKD12MaulN-Nk_IvQ" target="_blank" rel="noopener noreferrer"><img src={youtube} alt="Twitter Icon" height="40" /></a>&nbsp;
                        <a title="Like us on Facebook!" style={{margin:"0 5px"}} href="https://www.facebook.com/XLDynamicsindia" target="_blank" rel="noopener noreferrer"><img src={Facebook} alt="Facebook Like Icon" height="40" /></a>&nbsp;
                        <a title="Follow us on LinkedIn!" style={{margin:"0 5px"}} href="https://www.linkedin.com/company/xl-dynamics-india-pvt.-ltd" target="_blank" rel="noopener noreferrer"><img src={linkedin} alt="LinkedIn Icon" height="40" /></a>&nbsp;
                        <a title="Follow us on Twitter!" style={{margin:"0 5px"}} href="https://twitter.com/XLDynamics" target="_blank" rel="noopener noreferrer"><img src={Twitter} alt="Twitter Icon" height="40" /></a>&nbsp;
                    </Col>
                          
                    <Col xs={12} className="text-center">
                        <span>&copy; {new Date().getFullYear()} XL Dynamics India Pvt. Ltd. All Rights Reserved</span>
                    </Col>                   
                </Row>               
            </Container>
            <Image src={footerBar} fluid />
            </footer>
        </>
    )
}
