import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

import whoWeAreArrow from '../Images/Arrow_Who-we-are.png';
import otherArrow from '../Images/Small-arrow-for-other-3.png';

export default function About() {
    return (
        <>
            <Container fluid className="py-5 aboutContent">
                <Row className="align-items-center pb-4">
                    <Col md={12} lg={3}>
                        <h2 className="aboutTitle">WHO WE ARE</h2>
                    </Col>
                    <Col xs={1} className="px-0 d-none d-sm-none d-md-none d-lg-block">
                        <Image src={whoWeAreArrow} fluid alt="Arrow" />
                    </Col>
                    <Col md={12} lg={8}>
                        <p>XL Dynamics is a technology-driven mortgage process outsourcing company with over 15 years of experience. As one of the fastest growing fin-tech companies, we provide exceptional service, technology, and product innovation.</p>
                        <p>Our team of brilliant engineers are continuously filing new patents and expanding the boundaries of the financial services industry through innovations in mobile applications, customer acquisition and retention algorithms, and AI based process automation. We need people like you to join in our mission to lower the cost of financial services so that we can reach new and underserved markets.</p>
                    </Col>
                </Row>

                <Row className="align-items-center pb-4">
                    <Col md={12} lg={3}>
                        <h2 className="aboutTitle">WHAT YOU&#39;LL DO</h2>
                    </Col>
                    <Col xs={1} className="px-0 d-none d-sm-none d-md-none d-lg-block">
                        <Image src={otherArrow} fluid alt="Arrow" />
                    </Col>
                    <Col md={12} lg={8}>
                        <p>Work in a highly collaborative and creative team of talented engineers. Use creativity and innovation to solve problems in ways that have never been imagined before. Build and architect large-scale, data driven systems.</p>
                    </Col>
                </Row>

                <Row className="align-items-center pb-4">
                    <Col md={12} lg={3}>
                        <h2 className="aboutTitle">WHO YOU ARE</h2>
                    </Col>
                    <Col xs={1} className="px-0 d-none d-sm-none d-md-none d-lg-block">
                        <Image src={otherArrow} fluid alt="Arrow" />
                    </Col>
                    <Col md={12} lg={8}>
                        <p>A highly skilled and disciplined software engineer. We believe in using the right tool for the job. Be prepared to work on a wide variety of technologies. Although most of our hard core development is in Java, our development stack is integrated across C/C++, Java, Node JS, Oracle, and Amazon Lambda.</p>
                    </Col>
                </Row>

                <Row className="align-items-center pb-4">
                    <Col md={12} lg={3}>
                        <h2 className="aboutTitle">REQUIREMENTS</h2>
                    </Col>
                    <Col md={1} className="px-0 d-none d-sm-none d-md-none d-lg-block">
                        <Image src={otherArrow} fluid alt="Arrow" />
                    </Col>
                    <Col md={12} lg={8}>
                        <p>This position requires at least two years of experience. We are looking for <span className="mainBlue"><strong>talented engineers</strong></span> and <span className="mainBlue"><strong>architects</strong></span> who have an innate passion for creating beautiful and elegant systems.</p>
                        <p>If you fit this description, we would appreciate meeting with you to discuss a career at XL Dynamics.</p>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
 