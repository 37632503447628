import './App.css';
import Header from './components/Header';
import About from './components/About';
import Contact from './components/ContactUs';
import SubFooter from './components/SubFooter';
import Footer from './components/Footer';
import ReactGA from 'react-ga';

function App() {

  ReactGA.initialize('UA-187484893-2');
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <>
      <Header />
      <About />
      <Contact />
      <SubFooter />
      <Footer />
    </>
  );
}

export default App;
