import React from 'react';
// import StyledHero from './StyledHero';

import heroImage from '../Images/Header-02.svg';

export default function Header() {
    return (
        <>
            <img src={heroImage} alt="Hero iamge" className="w-100" />
        </>
    )
}
